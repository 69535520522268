/* eslint-disable relay/graphql-syntax */
// eslint-disable-next-line no-unused-vars
import React, {useCallback, useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import NavBarSimple from '../components/NavBarSimple';
import styled from 'styled-components';
import {BREAKPOINT} from '../helpers';
import GoogleAutoComplete from '../components/GoogleAutoComplete';
import CTAButton from '../components/styled/CTAButton';
import {get, isEmpty} from 'lodash';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Dialog from '@mui/material/Dialog';
import API from '../helpers/api';
// import TextInput from '../components/TextInput';
import validateEmail from '../helpers/validateEmail';
import {TextField} from '@mui/material';
import {v4 as uuidv4} from 'uuid';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
  align-items: center;
  min-height: calc(100vh - 100px);
  margin-top: 100px;

  @media (max-width: ${BREAKPOINT}px) {
    margin-top: 0px;
    padding: 100px 30px 30px 30px;
  }

  @media (max-width: 805px) {
    padding: 85px 15px 15px 15px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  max-width: 70%;

  @media (max-width: 805px) {
    max-width: 100%;
  }
`;

const Header = styled.div`
  text-align: center;
  font-size: 1.3em;
`;

const Subheader = styled.div`
  font-size: 1.15em;
  margin-bottom: 30px;
  text-align: center;
  color: gray;
`;

const AddRemoveButton = styled.button`
  border: 1px gray solid;
  border-radius: 13px;
  padding: 8px;
  font-size: 0.8em;
  background-color: transparent;
  color: gray;
`;

const ControlButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DialogContainer = styled.div`
  padding: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 70vw;
  text-align: center;
`;

// const EmailInput = styled(TextInput)`
//   border: 1px solid #8070fd;
//   width: 300px;

//   @media (max-width: 1092px) {
//     width: 100%;
//   }
// `;

const ErrorText = styled.p`
  color: #d32f2f;
  margin-top: 15px;
`;

const FieldsContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const LabelText = styled.div`
  font-size: 0.9em;
  margin: 16px 0px 12px 0px;
  padding: 0px 10px 0px 10px;
`;

const Separator = styled.div`
  background-color: lightgray;
  height: 1px;
  width: 100%;
  margin: 30px 0px 45px 0px;
`;

const SubmitPlacesButton = styled(CTAButton)`
  margin-top: 30px;
  width: 300px;
  font-size: 1.2em;
  position: sticky;
  position: -webkit-sticky;
  bottom: 10px;

  @media (max-width: 1092px) {
    width: 100%;
  }
`;

function isPlacesEmpty(places) {
  return isEmpty(places) || !Object.values(places).find((place) => !isEmpty(place.name) || !isEmpty(place.metadata));
}

function AddAPlacePage() {
  useEffect(() => {
    const refId = localStorage.getItem('user_temp_id');
    if (!refId) {
      localStorage.setItem('user_temp_id', uuidv4());
    }
  }, []);

  const {t} = useTranslation();

  const [places, setPlaces] = useState({});
  const [email, setEmail] = useState('');
  const [isModalShown, setIsModalShown] = useState(false);
  const [error, setError] = useState(false);

  const handleSelect = async (key, place) => {
    const currentPlace = places[key] || {};

    setPlaces((places) => ({
      ...places,
      [key]: {
        ...currentPlace,
        metadata: place,
      },
    }));

    if (!place) {
      return;
    }

    const refId = localStorage.getItem('user_temp_id');

    const {description: name} = place;
    const placeRefId = `${refId}:${key}`;
    const input = {
      places: [{refId: placeRefId, name, metadata: place}],
    };

    const body = JSON.stringify(input);

    try {
      await API.post('leads/suggest-places', {body});

      // eslint-disable-next-line no-empty
    } catch (e) {}

    // Send the selection to the server
  };

  const handleBlur = (key, fieldName) => async (event) => {
    const {value} = event.target;
    const refId = localStorage.getItem('user_temp_id');

    const placeRefId = `${refId}:${key}`;
    const input = {
      places: [{refId: placeRefId, [fieldName]: value}],
    };

    setPlaces((places) => ({
      ...places,
      [key]: {
        ...places[key],
        [fieldName]: value,
      },
    }));

    const body = JSON.stringify(input);

    try {
      await API.post('leads/suggest-places', {body});
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handleGooleFieldBlueBlur = (key) => async (event) => {
    // TODO: Handle this and submit name
  };

  const handlePlaceInput = (key) => (newInput) => {
    if (!places[key] || isEmpty(get(places[key], 'metadata'))) {
      setPlaces((places) => ({...places, [key]: {...places[key], name: newInput}}));
    }
  };

  const getPlaceFields = (key) => (
    <FieldsContainer>
      {key > 0 && <Separator />}
      <GoogleAutoComplete
        key={key}
        onSelect={(...args) => handleSelect(key, ...args)}
        onInputChange={handlePlaceInput(key)}
        style={{marginBottom: 10}}
        onBlur={handleGooleFieldBlueBlur(key)}
      />
      <TextField
        style={{marginBottom: 10}}
        label={t('Phone number')}
        variant="outlined"
        type="tel"
        autoComplete="false"
        onBlur={handleBlur(key, 'phoneNumber')}
      />
      <LabelText>
        <Trans>
          Please give us any relevant information like person to call, website, Facebook and/or Instagram profile and
          other.
        </Trans>
      </LabelText>
      <TextField
        autoComplete="false"
        style={{marginBottom: 10}}
        label={t('Additional information')}
        variant="outlined"
        onChange={(event) => {}}
        onBlur={handleBlur(key, 'additionalInfo')}
      />
    </FieldsContainer>
  );

  const [fields, setFields] = useState([getPlaceFields(0)]);

  const handleAddField = () => {
    setFields([...fields, getPlaceFields(fields.length)]);
  };

  const handleRemoveField = () => {
    if (fields.length > 1) {
      setFields((fields) => fields.slice(0, -1));
    } else {
      setTimeout(() => {
        // Note: HACK to clear the first field
        setFields([]);
        setFields([getPlaceFields(0)]);
      });
    }

    const key = fields.length - 1;
    setPlaces((places) => {
      delete places[key];

      return places;
    });
  };

  const handleSubmitButtonPress = () => {
    // Should we call the server here as well
    setIsModalShown(true);
  };

  const handleEmailChange = (event) => {
    if (error) {
      setError(false);
    }

    setEmail(event.target.value);
  };

  const handleSubmitEmailButtonPress = async (event) => {
    const isValid = validateEmail(email);
    if (!isValid) {
      setError('Invalid email!');
      return;
    }

    const refId = localStorage.getItem('user_temp_id');
    const _places = Object.keys(places).map((key) => {
      return {refId: `${refId}:${key}`, ...places[key], userEmail: email};
    });

    const input = {
      places: _places,
    };

    const body = JSON.stringify(input);

    try {
      await API.post('leads/suggest-places', {body});

      if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'CompleteRegistation', {email});
      }

      // eslint-disable-next-line no-empty
    } catch (e) {}

    // Reissue temp id so a new submission can be made
    localStorage.setItem('user_temp_id', uuidv4());

    setIsModalShown(false);

    setTimeout(() => {
      // Note: HACK to clear the first field
      setFields([]);
      setFields([getPlaceFields(0)]);
    });

    setPlaces({});
    setEmail('');
  };

  return (
    <main>
      <Helmet>
        <title>CindyApp - Add a place</title>
      </Helmet>
      <NavBarSimple />
      <PageContainer>
        <ContentContainer>
          <Header>
            <Trans>Add your favorite places below</Trans>
          </Header>
          <Subheader>
            <Trans>and you will be able to book appointments there with CindyApp</Trans>
          </Subheader>
          {fields.map((field) => field)}
          <ControlButtonsContainer>
            <AddRemoveButton style={{marginRight: 15}} onClick={handleRemoveField}>
              Премахни
              {/* <RemoveCircle fontSize="large" /> */}
            </AddRemoveButton>
            <AddRemoveButton onClick={handleAddField}>
              Добави още
              {/* <AddCircle fontSize="large" /> */}
            </AddRemoveButton>
          </ControlButtonsContainer>
          <SubmitPlacesButton onClick={handleSubmitButtonPress} disabled={isPlacesEmpty(places)}>
            <Trans>Submit</Trans>
          </SubmitPlacesButton>
          {/* <SubmitPlacesButton
            onClick={handleSubmitButtonPress}
            disabled={isEmpty(places)}
            style={{display: 'absolute', bottom: 20}}
          >
            <Trans>Submit</Trans>
          </SubmitPlacesButton> */}
        </ContentContainer>
        <Dialog onClose={() => setIsModalShown(false)} open={isModalShown}>
          <DialogContainer>
            <Trans>Thank you for your submission!</Trans>
            <br />
            <br />
            <p>
              <Trans>Please enter your email below so we can notify you when the place is added.</Trans>
            </p>
            <TextField
              required
              style={{width: '100%'}}
              label={t('email address')}
              variant="outlined"
              type="email"
              autoComplete="false"
              error={error}
              onChange={handleEmailChange}
              value={email}
            />
            {/* <EmailInput
              style={{marginTop: 20, width: '100%'}}
              name="email"
              type="email"
              placeholder={t('email address')}
              value={email}
              onChange={handleEmailChange}
            /> */}
            {error && (
              <ErrorText>
                <Trans>Please enter a valid email!</Trans>
              </ErrorText>
            )}
            <CTAButton
              style={{marginTop: 30, width: '100%'}}
              onClick={handleSubmitEmailButtonPress}
              disabled={isEmpty(email)}
            >
              <Trans>Notify me</Trans>
            </CTAButton>
          </DialogContainer>
        </Dialog>
      </PageContainer>
    </main>
  );
}

export default AddAPlacePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
